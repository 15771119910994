import { useState } from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import SearchOrder from './Components/SearchOrder';
import Login from './Components/Login';
import { Container, Card, Alert } from 'react-bootstrap';


function App() {
  const [isValid, setIsValid] = useState<boolean>(false)
  return (
    <Container>
      <Card className='p-2'>
        <h3>Wyszukiwarka zamówień</h3>

        {isValid && <SearchOrder />}
        {!isValid && (
          <>
            <Alert variant="warning">Musisz się zalogować by móc wyszukiwać zamówienia</Alert>
            <Login setIsValid={setIsValid} />
          </>
        )}
      </Card>
    </Container>


  )
}

export default App;
