import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import { Api } from '../Api/WooCommerceApi'
import { OrderItem } from '../Models/OrderItem';

const SearchOrder = () => {
    const [orderNumber, setOrderNumber] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [order, setOrder] = useState<OrderItem[] | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);

    const searchOrder = () => {
        setIsSearching(true);
        setOrder(undefined);
        setIsError(false);

        Api.get(`orders/${orderNumber}`)
            .then((res) => {
                const results = res.data;
                const products = results.line_items.map((item: any) => ({
                    name: item.name,
                    productId: item.product_id,
                    image: item.image.src,
                    quantity: item.quantity,
                    grammage: "0"
                } as OrderItem)) as OrderItem[]

                setOrder(products);

                const orders = [...products];
                orders.forEach(item => {
                    Api.get(`products/${item.productId}`)
                        .then((res) => {
                            item.grammage = res.data.weight;
                            setOrder(orders);
                        })
                })

            })
            .catch((err) => {
                setIsError(true);
            })
            .finally(() => {
                setIsSearching(false);
            });
    }

    return (
        <div className="SearchOrder">
            <Row className='pt-2'>
                <Col xs="10">
                    <Form.Group className="mb-3" >
                        <Form.Control type="text" placeholder='Numer zamówienia' onChange={(e) => { setOrderNumber(e.target.value); }} />
                    </Form.Group>
                </Col>
                <Col xs="2">
                    <Button variant="primary" onClick={searchOrder}>Wyszukaj</Button>
                </Col>
            </Row>

            <Row className='pt-2'>
                <Col>
                    {isSearching && <div style={{ display: "flex", justifyContent: "center" }}><Spinner animation="border" /></div>}
                </Col>
            </Row>
            {order && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Obrazek</th>
                            <th>Nazwa produktu</th>
                            <th>Gramatura</th>
                            <th>Ilość</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.map((item, index: number) => {
                            return (
                                <tr key={item.productId}>
                                    <td>{index}</td>
                                    <td> <Image src={item.image} width="200" height="auto" rounded /></td>
                                    <td>{item.name}</td>
                                    <td>{item.grammage}</td>
                                    <td>{item.quantity}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            )}
            {isError && (<Alert variant='danger'>Wystąpił błąd z połączeniem</Alert>)}
        </div>
    );
}

export default SearchOrder