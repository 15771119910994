import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export interface LoginProps {
    setIsValid: (status: boolean) => void
}

const Login = ({ setIsValid }: LoginProps) => {
    const [password, setPassword] = useState("");

    const verifyPassword = () => {
        setIsValid(process.env.REACT_APP_PASSWORD === password);
    }

    return (
        <>
            <Row className='pt-2'>
                <Col xs="10">
                    <Form.Group className="mb-3" >
                        <Form.Control type="password" placeholder='Podaj hasło' onChange={(e) => { setPassword(e.target.value); }} />
                    </Form.Group>
                </Col>
                <Col xs="2">
                    <Button variant="primary" onClick={verifyPassword}>Zaloguj się</Button>
                </Col>
            </Row>
        </>
    )
}

export default Login